<template>
    <div class="contaners_normal">
        <div class="share_time">
            <div v-if="status==1"><span class="dataText" >{{userFrom.renew.rest}}</span>天</div>
            <div v-else ><span class="dataText" >{{userFrom.shareholder_renew.rest}}</span>天</div>
            <div class="share_text" v-if="status==1">
                <div>当前账号权益结束日期为</div>
                <div>{{userFrom.union_expire_at}}</div>
            </div>
            <div class="share_text" v-else>
                <div>劳务合约截止日期为</div>
                <div>{{userFrom.union_shareholder_expire_at}}{{status}}</div>
            </div>
            
        </div>
        <!-- 权益 -->
        <div class="equities">
            <div class="text_box">
                <div class="ot_title">尊享8大权益</div>
                <div class="ot_text">Account rights</div>
            </div>
            <div class="lader_text">
                <div>尊贵身份</div> 
                <div>尽享不同</div>
            </div>
            <div class="item_info">
                <div>
                    <div><img src="../../assets/Slice17.png"/></div>
                    <div>会员专享</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice18.png"/></div>
                    <div>福利商城</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice19.png"/></div>
                    <div>超值礼包</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice20.png"/></div>
                    <div>绩效提成</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice21.png"/></div>
                    <div>工分份转让</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice22.png"/></div>
                    <div>团队孵化</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice23.png"/></div>
                    <div>专属会场</div>
                </div>
                <div>
                    <div><img src="../../assets/Slice24.png"/></div>
                    <div>择优政策</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        userFrom:{
            type:Object
        },
        status:{
            type:Number
        }
    }
};
</script>
<style>
.contaners_normal {
}
.share_time {
  width: 700px;
  height: 180px;
  background: linear-gradient(260deg, #e3c084 0%, #eed09c 100%);
  border-radius: 20px 20px 20px 20px;
  opacity: 1;
  margin: 50px auto;
  padding: 34px 54px;
  box-sizing: border-box;
  color:rgba(255, 255, 255, 1);
  font-size: 30px;
  display: flex;

}
.dataText{
    font-size: 80px;
    color:#fff
}
.share_text{
    margin-left:26px;
    margin-top: 10px
}
.share_text div{
    margin-bottom: 10px
}
.item_info{
    display: flex;
    flex-wrap: wrap;
    font-size: 26px;color:rgba(97, 97, 97, 1);
    margin-left:30px
}
.item_info img{
    width:95px;height:95px
}
.item_info>div{
    width:170px;
    text-align: center
}

.lader_text{
    font-size: 24px;color:rgba(176, 176, 176, 1);
    display:flex;;
    margin-left:55px;
    margin-bottom: 30px
}
.lader_text div{
    margin-right:10px
}
.ot_title{
    font-size: 36px;color:rgba(0, 0, 0, 1)
}
.ot_text{
    font-size: 20px;
    color:#000;
    margin-left:10px
}
.text_box{
    margin-left:55px;
    display:flex;
    line-height: 40px;
    margin-bottom: 15px
}
</style>

