<template>
  <div>
    <div class="user_header">
      <div style="display:flex">
        <div class="userImg">
          <img :src="userFrom.user_avatar||''" />
        </div>
        <div>
          <div class="user_name">{{userFrom.member.member_nick}}</div>
          <div>
            <img class="imgvip" src="../assets/vipicon2.png" />
            <div class="level">v{{userFrom.member.vip_level}}</div>
            <img class="imglador" v-if="userFrom.union_member!=0" src="../assets/ladoricon.png" />
            <img class="imgshare" v-if="userFrom.union_shareholder!=0" src="../assets/gufen.png" />
          </div>
        </div>
        <div class="message" @click="userBtn">个人资料></div>
      </div>
      <div>
        <!-- 会员续签处理 -->
        <!-- <div v-if="userFrom.vipUser==1">-->
        <div v-if="type==1&&!id">
          <div v-if="userFrom.renew.state==1">
            <div v-if="!userFrom.renew.audit||userFrom.renew.audit.audit_state==3" class="confim_box_u" @click="listBtn(1)">立即续费</div>
            <div v-else>
              <div class="confim_box1_u">
                <img src="../assets/Slice32.png">
                <div @click="confimBtn">处理中</div>
              </div>
              <!--<div class="confim_box_u" v-else @click="listBtn(1)">立即续费</div> -->
            </div>
          </div>

        </div>
        <!-- </div> -->

        <!-- 合约续签处理 -->
        <div v-if="type!=1&&!id">
          <div v-if="userFrom.shareholder_renew.state==1">
            <div class="confim_box_u" v-if="!userFrom.shareholder_renew.audit||userFrom.shareholder_renew.audit.audit_state==3" @click="listBtn">立即处理</div>
            <div class="confim_box1_u" v-else>
              <img src="../assets/Slice32.png">
              <div @click="confimBtn1">处理中</div>
            </div>
          </div>
          <!-- <div v-if="userFrom.shareholder_renew.audit">
              
              <div class="confim_box_u" v-if="userFrom.shareholder_renew.state==1&&(userFrom.shareholder_renew.audit.audit_state==3||!userFrom.shareholder_renew.audit)" @click="listBtn">立即处理</div>
            </div> -->
        </div>

        <div class="history" @click="historyBtn">历史记录</div>
      </div>

      <div class="vipTime" v-if="type==1">会员有效日期：{{userFrom.union_expire_at}}</div>
      <div class="vipTime" v-else>劳务合同有效日期：{{userFrom.union_shareholder_expire_at}}</div>
    </div>

  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  props: {
    userFrom: {
      type: Object
    },
    type: {
      type: String
    },
    id: {
      type: Number
    }
  },
  methods: {
    historyBtn() {
      if (this.type == 1) {
        this.$router.push("/expenditure/history");
      } else {
        this.$router.push("/expenditure/g_history");
      }
    },
    userBtn() {
      this.$router.push("/deal/mine");
    },
    confimBtn1() {
      console.log(111);
      this.$router.push(
        `/expenditure/vipUser/contract?id=${
          this.userFrom.shareholder_renew.audit.audit_id
        }`
      );
      this.$emit("confimBtn1");
    },
    confimBtn() {
      this.$emit("confimBtn");
    },
    listBtn(index) {
      //会员
      if (index == 1) {
        //  if (this.userFrom.renew.audit&&this.userFrom.renew.audit.audit_state!=2&&this.userFrom.renew.audit.audit_state!=0) {
        //     this.$router.push(
        //       `../vipUser/contract?type=1&id=${
        //         this.userFrom.renew.audit.audit_id
        //       }`
        //     );
        //     return false;
        //   }

        //   if (this.userFrom.vipUser == 1) {
        //     this.$router.push("../vipUser?status=1&type=1");
        //   } else {
        //    this.$router.push("./vipUser/contract?status=1&type=1")
        //   }
        this.$router.push("../../lador/service?type=1");
        // 股东
      } else { 
        // if (this.userFrom.shareholder_renew.audit&&this.userFrom.shareholder_renew.audit.audit_state!=2) {
        //         this.$router.push(
        //         `../vipUser/contract?id=${
        //           this.userFrom.shareholder_renew.audit.audit_id
        //         }`
        //        );
        //       return false;
        //       }
        //       if (this.userFrom.vipShare == 1||this.userFrom.shareholder_renew.state==1) {
        //           this.$router.push("../vipUser?status=2");
        //       } else {
        //         this.$router.push("./vipUser/contract")
        //       }
        this.$router.push("../vipUser?status=2");
      }
    }
  }
};
</script>
<style>
.level {
  position: absolute;
  top: 113px;
  left: 220px;
  font-size: 20px;
  color: #fff;
}
.user_header {
  width: 750px;
  height: 400px;
  background-image: url(../assets/Slice14.png);
  background-size: 100% 100%;
  margin: 32px auto 62px;
  padding: 38px 45px;
  box-sizing: border-box;
  position: relative;
  box-shadow: 0px 5px 15px 0px rgba(175, 106, 0, 0.2);
}
.userImg {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  background-color: #bc904d;
  margin-right: 26px;
}
.userImg img {
  width: 100%;
  height: 100%;
  border-radius: 50%;
}
.imgvip {
  width: 87px;
  height: 45px;
}
.imglador {
  width: 46px;
  height: 46px;
  margin: 0 10px;
}
.imgshare {
  width: 46px;
  height: 46px;
}
.user_name {
  font-size: 30px;
  color: #fff;
  margin-bottom: 10px;
  margin-top: 5px;
}
.message {
  font-size: 26px;
  color: #fef8ec;
  position: absolute;
  top: 50px;
  right: 50px;
}
.history {
  width: 190px;
  height: 74px;
  background: #f1c67a;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  text-align: center;
  line-height: 74px;
  font-size: 30px;
  color: #fff;
  margin: 18px 0 0 490px;
}
.vipTime {
  margin: 90px auto 0;
  font-size: 26px;
  color: #fef8ec;
}
.confim_box_u {
  width: 190px;
  height: 74px;
  background: #f1c67a;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 74px;
  margin-left: 50px;
  position: absolute;
  bottom: 150px;
  left: 30px;
}
.confim_box1_u {
  width: 190px;
  height: 74px;
  background: #f1c67a;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  font-size: 30px;
  color: #fff;
  text-align: center;
  line-height: 74px;
  margin-left: 50px;
  display: flex;
  position: absolute;
  bottom: 150px;
  left: 50px;
}
.confim_box1_u img {
  width: 36px;
  height: 36px;
  margin: 15px 10px 0 20px;
}
</style>
