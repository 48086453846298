<template>
  <div class="container_con">
    <clone-tab :bgcShow="bgcShow"></clone-tab>
    <!-- 用户信息 -->
    <user-info :id="id" @confimBtn="confimBtn" @confimBtn1="confimBtn1" :userFrom="userFrom" :type="queryList.type"></user-info>
    <!-- 审核流程 -->
    <audit-process :tableData="tableData" :type="queryList.type" :dataList="dataList" v-if="id"></audit-process>

    <!-- 账号正常状态-->
    <normal :userFrom="userFrom" :status="status" v-else></normal>
    <div v-if="dataList&&dataList.audit_state==3" @click="submitBtn" class="submitBtn_t">重新提交</div>
  </div>
</template>
<script>
import cloneTab from "../../../components/expenditure/cloneTab";
import userInfo from "../../../components/userInfo";
import auditProcess from "../../../components/expenditure/auditProcess";
import normal from "../../../components/expenditure/normal";
import { applyDetail_api, shareDetail_api } from "@/api/deal";
// 数据
import * as apiCommon from "@/api/common";
export default {
  data() {
    return {
      bgcShow: true, //签名
      auditShow: true, //审核状态
      id: "",
      dataList: {},
      userFrom: {
        member: {},
        renew: {},
        shareholder_renew: {}
      },
      queryList: null,
      status: null,
      type: null,
      tableData: {}
    };
  },
  components: {
    cloneTab,
    userInfo,
    auditProcess,
    normal
  },
  created() {
    this.queryList = this.$route.query;
    if (this.queryList.type == 1) {
      document.title = "授权中心";
    } else {
      document.title = "续约中心";
    }
    this.id = this.$route.query.id;
    this.status = this.$route.query.status;
    this.type = this.$route.query.type;
    if (this.id) {
      if (this.$route.query.type != 1) {
        this.getShareDetails(this.$route.query.id);
      } else {
        this.getDetails(this.$route.query.id);
      }
    }

    this.getUserInfo();
    this.getSetting();
  },
  methods: {
    confimBtn() {
      document.title = "授权中心";
      this.id = this.userFrom.renew.audit.audit_id;
      this.status = this.queryList.status;
      this.type = this.queryList.type;
      if (this.id) {
        if (this.queryList.type != 1) {
          this.getShareDetails(this.userFrom.renew.audit.audit_id);
        } else {
          this.getDetails(this.userFrom.renew.audit.audit_id);
        }
      }

      this.getUserInfo();
      this.getSetting();
    },
    confimBtn1() {
      document.title = "续约中心";
      this.id = this.userFrom.shareholder_renew.audit.audit_id;
      this.status = this.queryList.status;
      this.type = this.queryList.type;
      if (this.id) {
        if (this.queryList.type != 1) {
          this.getShareDetails(this.userFrom.shareholder_renew.audit.audit_id);
        } else {
          this.getDetails(this.userFrom.shareholder_renew.audit.audit_id);
        }
      }

      this.getUserInfo();
      this.getSetting();
    },
    async getSetting() {
      let data = {
        config: ["union_member_fee", "shareholder_renew_shipping_fee"]
      };
      let res = await apiCommon.configSetting(data);
      if (res.code == 0) {
        console.log(res.data, "111111");
        this.tableData = res.data;
      }
    },
    async getDetails(id) {
      await applyDetail_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;

          if (!this.dataList.pay_at) {
            this.dataList.levelStatus = 1;
          }
          if (this.dataList.pay_at && this.dataList.audit_state == 1) {
            this.dataList.levelStatus = 2;
          }
          if (this.dataList.audit_state == 2) {
            this.dataList.levelStatus = 3;
          }
          if (this.dataList.shipments.shipments_info) {
            this.dataList.levelStatus = 4;
          }
          console.log(this.dataList);
        }
      });
    },
    getShareDetails(id) {
      shareDetail_api(id).then(res => {
        console.log(res);
        if (res.code == 0) {
          this.dataList = res.data;
          if (!this.dataList.pay_at) {
            this.dataList.levelStatus = 1;
          }
          if (this.dataList.pay_at && this.dataList.audit_state == 1) {
            this.dataList.levelStatus = 2;
          }
          if (this.dataList.audit_state == 2) {
            this.dataList.levelStatus = 3;
          }
        }
      });
    },
    // 获取用户信息
    getUserInfo() {
      apiCommon.getuser_api().then(res => {
        console.log(res, "res");
        if (res.code == 0) {
          this.userFrom = res.data;
          if (!this.userFrom.renew) {
            this.userFrom.renew = {};
          }
          if (!this.userFrom.shareholder_renew) {
            this.userFrom.shareholder_renew = {};
          }
          if (this.userFrom.union_expire_at) {
            if (
              new Date().getTime() >
              new Date(this.userFrom.union_expire_at).getTime()
            ) {
              this.userFrom.vipUser = 1;
            } else {
              this.userFrom.vipUser = 0;
            }
          } else {
            this.userFrom.vipUser = 3;
          }
          if (this.userFrom.union_shareholder_expire_at) {
            if (
              new Date().getTime() >
              new Date(this.userFrom.union_shareholder_expire_at).getTime()
            ) {
              this.userFrom.vipShare = 1;
            } else {
              this.userFrom.vipShare = 0;
            }
          } else {
            this.userFrom.vipShare = 3;
          }
          this.userFrom.vipNum = this.DateDiff(
            res.data.union_auth_at,
            res.data.union_expire_at
          );
          this.userFrom.shareNum = this.DateDiff(
            res.data.union_shareholder_auth_at,
            res.data.union_shareholder_expire_at
          );
          console.log(this.userFrom.vipNum);
        }
      });
    },
    DateDiff(start, end) {
      let days, today;
      today = new Date();

      end = new Date(end);
      if (end > today) {
        days = parseInt(Math.abs(end - today) / 1000 / 60 / 60 / 24);
      } else {
        days = parseInt(Math.abs(end - today) / 1000 / 60 / 60 / 24);
        days = 0;
        // 如果不限制对比时间和当前时间大小可以不用if
      }
      return days;
    },
    submitBtn() {
      if (this.queryList.type == 1) {
        this.$router.push("../vipUser?status=1");
      } else {
        this.$router.push("../vipUser?status=2");
      }
    }
  }
};
</script>
<style>
.container_con {
  padding-top: 40px;
}
.submitBtn_t {
  width: 400px;
  height: 90px;
  background: #2d2929;
  border-radius: 50px 50px 50px 50px;
  opacity: 1;
  border: 2px solid #e7c48b;
  text-align: center;
  line-height: 90px;
  font-size: 30px;
  color: rgba(231, 197, 140, 1);
  margin: 30px auto;
}
</style>